import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this._keyDifficulty = 'difficulty'
    this._keyPage = 'page'
    this._keyType = 'type'
    this._activeItemClass = 'courses-index-header__filtering-button-item-active'
    this._inactiveItemClass = 'courses-index-header__filtering-button-item-inactive'
    this._$coursesContainer = $('#courses_container')
    this._showInitialCourses()
  }

  _bindClickToFilterButtons() {
    $('.courses-index-header__filtering-button-item').on('click', this._filterCourses.bind(this))
  }

  _unbindClickToFilterButtons() {
    $('.courses-index-header__filtering-button-item').off('click')
  }

  _showInitialCourses() {
    let filters = {}

    if (page) {
      filters[this._keyPage] = page
    }

    let hashString = document.location.hash
    if (hashString != '') {
      hashString = hashString.replace('#', '')
      hashString.split('&').forEach((item) => {
        let [key, value] = item.split('=')
        filters[key] = decodeURIComponent(value)
      })
    }

    this._getCourses(filters)
  }

  _filterCourses(event) {
    this._unbindClickToFilterButtons()
    const $this = $(event.target)
    const pageSelected = $this.data(this._keyPage)
    const difficultySelected = $this.data(this._keyDifficulty)
    const type = $this.data(this._keyType)

    let filterParams = { page: pageSelected }
    if (difficultySelected && difficultySelected != 'all') {
      filterParams.difficulty = difficultySelected
    }
    if (type) {
      filterParams.type = type
    }

    this._getCourses(filterParams)
  }

  _getCourses(filterParams) {
    this._setFilterParamsToLocationHash(filterParams)
    $('#courses').hide()
    $('#courses-loading-msg').show()
    filterParams['ajax'] = true
    $.ajax({
      type: 'GET',
      url: courses_base_url,
      data: filterParams,
      dataType: 'html',
      complete: this._onFilterCoursesComplete.bind(this)
    })
  }

  _setFilterParamsToLocationHash(filterParamsMap) {
    let params = []
    for (const paramName in filterParamsMap) {
      if ([this._keyDifficulty, this._keyType].includes(paramName)) {
        params.push(paramName + '=' + filterParamsMap[paramName])
      }
    }

    const currUrl = document.location.href
    const baseUrl = currUrl.split('#')[0].split('?')[0]
    let newBaseUrl = baseUrl

    if (filterParamsMap.page && filterParamsMap.page != 1) {
      newBaseUrl = baseUrl + '?page=' + filterParamsMap.page
    }

    const ga = new URL(currUrl).searchParams.get("_ga");

    if (currUrl == newBaseUrl) {
      document.location.hash = params.join('&')
    } else if (params.length == 0 && !ga) {
      document.location.href = newBaseUrl
    } else if (params.length != 0){
      document.location.href = (newBaseUrl + '#' + params.join('&'))
    }
  }

  _onFilterCoursesComplete(response) {
    this._$coursesContainer.html(response.responseText)
    this._$coursesContainer.addClass("loaded")
    this._bindClickToFilterButtons()
  }
}
