import * as $ from 'jquery'
window.$ = window.jQuery = $
import './stylesheets/courses.sass'
require('components/views/tabs')
import application from 'components/stimulus_app'
import { definitionsFromContext } from "stimulus/webpack-helpers"
import 'components/base'

application.load(definitionsFromContext(require.context("controllers/courses/", true, /\.js$/)))
application.load(definitionsFromContext(require.context("controllers/common/", true, /(card_carousel)_controller\.js$/)))
