/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => $('.app-tabs > .tabs > .tab:not(.disabled)').on('click', function() {
  const $this = $(this);
  const tabContentToShow = $this.data('tab-content-to-show');
  if (tabContentToShow) {
    const $tabs = $this.parent('.tabs');
    const $tabsContent = $tabs.siblings('.tabs-content');
    if ($tabsContent && ($tabsContent.length>0)) {
      $tabs.children('.tab').removeClass('active');
      $this.addClass('active');
      $tabsContent.children('.tab-content').removeClass('active');
      return $tabsContent.children('#' + tabContentToShow).addClass('active');
    }
  }
}));
